// Styles for MpoApoOfferDetails go here.
import styled from 'styled-components';
import { baseVariables, toRem } from '@marriott/mi-ui-library';
import { mediaQuery, font } from '../../../utils/styles';

export const StyledOfferDetailsMultiProperty = styled.div`
  background-color: ${baseVariables.color.neutral10};
  @media ${mediaQuery.mobileOnly} {
    padding-top: ${toRem(48)};
  }
  .mpo-detail-section {
    .container {
      display: flex;
      justify-content: space-between;
    }

    .m-details-offer-description {
      a {
        text-decoration: underline;
      }
    }
    padding: ${toRem(60)} ${toRem(70)};
    .details-left {
      max-width: 45%;
      margin-right: 5%;
      .title {
        margin-bottom: ${toRem(10)};
        .mpo-details-title {
          color: ${baseVariables.color.merch30};
          font-family: ${font.Swiss721BTRegular};
          font-size: ${toRem(30)};
          font-weight: 500;
          letter-spacing: 0;
          line-height: ${toRem(32)};
          margin: 0;
        }
      }

      .desc,
      .m-details-page-reserv-msg {
        margin-bottom: ${toRem(16)};
        p,
        li {
          color: ${baseVariables.color.merch30};
          font-family: ${font.Swiss721BTRegular};
          font-size: ${toRem(16)};
          letter-spacing: 0;
          line-height: ${toRem(24)};
          margin: 0;
          a {
            font-size: ${toRem(16)};
            font-weight: 600;
            text-decoration: underline;
          }
        }
      }
      .m-details-page-reserv-msg h3 {
        font-size: ${toRem(16)};
        font-family: ${font.Swiss721BTRegular};
      }
      .terms-link {
        a {
          cursor: pointer;
          font-family: ${font.Swiss721BTRegular};
          font-size: ${toRem(13)};
          font-weight: 700;
          line-height: ${toRem(16)};
        }
      }
      &[class^='icon-'] {
        padding: 0 0.25rem;
        font-size: 0.75rem;
      }
      span.icon-forward-arrow {
        margin-left: ${toRem(6)};
      }
      span.icon-forward-arrow::before {
        font-family: MiIcons;
        color: var(--t-link-text-color);
      }
    }
    @media ${mediaQuery.tabletOnly} {
      padding: ${toRem(40)} ${toRem(32)};
      .details-left {
        max-width: ${toRem(320)};
        margin-right: ${toRem(76)};
        .title {
          margin-bottom: ${toRem(10)};
          p {
            font-size: ${toRem(22)};
            line-height: ${toRem(26)};
          }
        }
        .desc,
        .m-details-page-reserv-msg {
          margin-bottom: ${toRem(16)};
          p {
            font-size: ${toRem(14)};
            line-height: ${toRem(20)};
          }
        }
        .terms-link {
          font-size: ${toRem(13)};
          line-height: ${toRem(16)};
        }
      }
    }
    .details-right {
      width: 45%;
      .title {
        padding-bottom: ${toRem(15)};
        border-bottom: ${toRem(1)} solid ${baseVariables.color.alert20};
        text-transform: uppercase;
        p {
          color: ${baseVariables.color.merch30};
          font-family: ${font.Swiss721BTRegular};
          font-size: ${toRem(12)};
          font-weight: 500;
          letter-spacing: ${toRem(1.3)};
          line-height: ${toRem(16)};
          margin: 0;
        }
      }
      ul {
        padding-left: 0;
        margin-top: 0;
        li {
          list-style-type: none;
          font-family: ${font.Swiss721BTRegular};
          font-size: ${toRem(16)};
          font-weight: 500;
          letter-spacing: ${toRem(1.3)};
          line-height: ${toRem(20)};
          padding: ${toRem(15)} ${toRem(0)};
          border-bottom: ${toRem(1)} solid ${baseVariables.color.alert20};
          a.m-whats {
            font-size: ${toRem(12)};
            text-decoration: none;
            margin-left: ${toRem(5)};
            cursor: pointer;
          }
          a.m-whats:hover + .m-tool-tip-hint,
          a.m-whats:focus + .m-tool-tip-hint {
            visibility: visible;
          }
          [class^='icon-'] {
            padding: 0 ${toRem(4)};
            font-size: ${toRem(12)};
          }
          &.promo-code-list {
            position: relative;
          }
        }
        .m-tool-tip-hint {
          box-sizing: border-box;
          visibility: hidden;
          text-align: left;
          border-radius: 0.25rem;
          padding: 1rem;
          position: absolute;
          z-index: 1;
          width: ${toRem(270)};
          background-color: #fff;
          color: ${baseVariables.color.merch30};
          border: 0.0625rem solid #d2d2d2;
          font-weight: 500;
          font-size: ${toRem(12)};

          @media ${mediaQuery.tabletOnly} {
            left: ${toRem(20)};
            top: 84%;
          }

          @media ${mediaQuery.mobileOnly} {
            left: ${toRem(20)};
            top: 84%;
            margin-left: auto;
            margin-right: auto;
          }
        }
      }
      @media ${mediaQuery.tabletOnly} {
        width: 48%;
        ul {
          li {
            font-size: ${toRem(14)};
            padding: ${toRem(13)} ${toRem(0)};
          }
        }
      }
      @media ${mediaQuery.mobileOnly} {
        width: 100%;
      }
    }

    @media ${mediaQuery.mobileOnly} {
      .container {
        flex-direction: column;
      }
      padding: ${toRem(40)} 0;
      .details-left {
        max-width: unset;
        margin-right: 0;
        margin-bottom: ${toRem(35)};
        .title {
          p {
            font-size: ${toRem(22)};
            line-height: ${toRem(26)};
            font-weight: 500;
          }
        }
      }
      .details-right {
        ul {
          li {
            padding: ${toRem(12)} 0;
            font-size: ${toRem(14)};
          }
        }
      }
    }
    @media ${mediaQuery.desktop} {
      .details-right {
        ul {
          li {
            font-size: ${toRem(16)};
          }
        }
      }
    }
    @media ${mediaQuery.tabletOnly} {
      .container {
        max-width: unset;
      }
    }
  }
`;
